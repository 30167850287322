import React from 'react';
import { FileCardStyles } from './FileCard.styles';
import { ReactComponent as Pdf } from '@img/icons/pdf.svg';
import { ReactComponent as Chevron } from '@img/icons/chevron.svg';
const FileCard = (props) => {
    return (React.createElement(FileCardStyles.Card, { href: props.redirectUrl !== null ? props.redirectUrl : props.file.url, target: "_blank", draggable: false },
        React.createElement(FileCardStyles.TagArea, null,
            props.file.tag ? React.createElement(FileCardStyles.Tag, null, props.file.tag) : null,
            props.file.groundforceDivision && props.file.groundforceDivision.name.length > 0 &&
                React.createElement(FileCardStyles.DivisionTag, { "data-tag-colour": props.file.groundforceDivision.class }, props.file.groundforceDivision.name)),
        React.createElement(FileCardStyles.Media, null,
            React.createElement(FileCardStyles.Icon, null,
                React.createElement(Pdf, null)),
            React.createElement(FileCardStyles.Size, null, props.file.size)),
        React.createElement(FileCardStyles.Name, null, props.file.name),
        props.file.content && React.createElement(FileCardStyles.Content, null, props.file.content),
        React.createElement(FileCardStyles.Footer, null,
            React.createElement(Chevron, null))));
};
export default FileCard;
